// extracted by mini-css-extract-plugin
export var header = "W_H";
export var item = "W_cg";
export var itemImage = "W_cB";
export var itemIsExternal = "W_cS";
export var itemIsInView = "W_cQ";
export var itemIsLinked = "W_cR";
export var itemText = "W_cC";
export var itemTime = "W_cT";
export var itemTimeIsBig = "W_cV";
export var logo = "W_bH";
export var root = "W_b";